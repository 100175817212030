<a id="initpos"></a>
<span class="p-2 m-1 float-end btn-close pointer" (click)="activeModal.close('Close click'); clearLocalStorage()" style="z-index: 3;position: absolute; right: 0px;">
</span>
<section>
  <div class="col-12 p-3">
    <div class="card-body" style="max-width: 1200px;">
      <div class="row">
        <div class="col-12">
          <h4 class="card-title text-bold-500 primary mb-0">{{'feedback.t1' | translate }}</h4>
        </div>
        <div class="col-12">
          <p class="primary">{{'feedback.subt1' | translate }}</p>
      </div>
    </div>
      <div class="row">
        <div class="col-12">
            <form class="mt-2" [formGroup]="formulario" (ngSubmit)="sendFeedback()">
                <div class="row">
                  <p for="pregunta1" class="col-md-8 text-bold-400 mb-0" style="margin-top: 1rem !important;">{{'feedback.q1' | translate }}</p>
                  <ngb-rating formControlName="pregunta1" max="5" class="my-rating col-md-4"></ngb-rating>
                </div>
                <div class="row">
                  <p for="pregunta2" class="col-md-8 text-bold-400 mb-0 mt-2" style="margin-top: 1rem !important;">{{'feedback.q2' | translate }}</p>
                  <ngb-rating formControlName="pregunta2" max="5" class="my-rating col-md-4"></ngb-rating>
                </div>
                <div class="row">
                  <p for="userType" class="col-md-12 text-bold-400 mb-0 mt-2" style="margin-top: 1rem !important;">{{'feedback.userType' | translate }}</p>
                  <div class="col-md-12 mt-1">
                      <mat-radio-group formControlName="userType">
                          <mat-radio-button class="mat-radio-button me-3" value="doctor">{{'feedback.doctor' | translate }}</mat-radio-button>
                          <mat-radio-button class="mat-radio-button me-3" value="patient">{{'feedback.patient' | translate }}</mat-radio-button>
                          <mat-radio-button class="mat-radio-button me-3" value="caregiver">{{'feedback.caregiver' | translate }}</mat-radio-button>
                          <mat-radio-button class="mat-radio-button" value="none">{{'feedback.none' | translate }}</mat-radio-button>
                      </mat-radio-group>
                  </div>
              </div>
                <div class="mt-3">
                  <p for="moreFunct" class="text-bold-400 mb-0">{{'feedback.q3' | translate }} ({{'generics.Optional' | translate }})</p>
                  <textarea id="moreFunct" name="moreFunct" maxlength="2000" formControlName="moreFunct" rows="4" class="form-control" (input)="onInput($event, 'moreFunct')"></textarea>
                  <small class="form-text text-muted">{{ moreFunctLength }} / 2000</small>
                </div>
                <div class="mt-3">
                    <p for="freeText" class="text-bold-400 mb-0">{{'feedback.commment' | translate }} ({{'generics.Optional' | translate }})</p>
                    <textarea id="freeText" name="freeText" maxlength="2000" formControlName="freeText" rows="4" class="form-control" (input)="onInput($event, 'freeText')"></textarea>
                    <small class="form-text text-muted">{{ freeTextLength }} / 2000</small>
                  </div>
                  <div class="mt-3 mb-3">
                    <p for="email" class="text-bold-400 mb-0">{{'feedback.emailquestion' | translate }} ({{'generics.Optional' | translate }})</p>
                    <input type="email" formControlName="email" name="email" class="form-control" placeholder="{{'generics.Email' | translate }}" autocomplete="email">
                  </div>
                  <mat-checkbox class="example-margin" [(ngModel)]="terms2" formControlName="terms2" (change)="changeTerm($event)">
                    <label style="margin-top: 0.2em;white-space: normal;text-transform: none;letter-spacing: 0px;letter-spacing: 0.3px;font-weight: 400 !important;"  >
                      <span (click)="showOptions()">{{'land.check' | translate }}</span>
                    </label>
                  </mat-checkbox>
                <div class="col-12 center-elements mt-3" *ngIf="!sending">
                  <button type="button" class="btn btn-secondary btn-lg me-1" (click)="activeModal.close('Close click'); clearLocalStorage()">{{'generics.Close' | translate }}</button>
                  <button type="submit" class="btn btn-info btn-lg ms-1">{{'generics.Send' | translate }}</button>
                </div>
                <div class="col-md-12 center-elements" *ngIf="sending">
                    <span ><i class="fa fa-spinner fa-spin fa-3x fa-fw pink"></i></span>
                </div>
                
              </form>
        </div>
        
    </div>
    </div>
</div>
</section>
